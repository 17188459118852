<template>
  <v-app class="bg-surfaceLight text-onSurfaceLight">
    <v-app-bar absolute color="primaryContainerLight">
      <v-app-bar-title>
        <LogoHorizontal color="onPrimaryContainerLight" class="pa-2 d-block" />
      </v-app-bar-title>
    </v-app-bar>
    <v-main class="pt-16">
      <v-container fluid class="">
        <slot />
      </v-container>
    </v-main>
    <FlowFooter />
  </v-app>
</template>

<style lang="scss" scoped>
html,
body {
  overscroll-behavior: contain;
}

:deep(.v-input) {
  --v-input-control-height: 24px; // fix for position of floating label on input fields
}
</style>
