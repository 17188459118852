<template>
  <v-footer
    id="footer"
    min-height="60"
    class="w-100 bg-surfaceLight text-onSurfaceLight"
  >
    <v-container class="pl-0">
      <v-row class="justify-center justify-md-space-between align-center">
        <v-col
          cols="12"
          md="8"
          class="d-flex flex-wrap justify-md-start justify-center pb-sm-0 pb-md-3"
        >
          <v-btn
            v-for="page in pages"
            :key="page.title"
            variant="text"
            color="onSurfaceVariantLight"
            class="mx-2 text-subtitle-2"
            :to="page.link"
            target="_blank"
            nuxt
          >
            {{ page.title }}
          </v-btn>
        </v-col>
        <v-col
          class="text-center text-md-right text-subtitle-2"
          cols="12"
          md="4"
        >
          &copy; {{ date }} OnlineCarValues.com
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script setup lang="ts">
const date = new Date().getFullYear();
const pages = [
  // {
  //   title: "Accessibility Statement",
  //   link: "/accessibility-statement",
  // },
  {
    title: "Terms & Conditions",
    link: "/terms-conditions",
  },
  {
    title: "Privacy Policy",
    link: "/privacy-policy",
  }
];
</script>
